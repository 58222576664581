import React from 'react';
import { Modal, Card, CardContent, Typography } from '@mui/material';
import { styled, CSSObject } from '@mui/material/styles';

const imagen = require('../assets/caminar.gif')

const LoadingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  minHeight: '100vh', // Ajusta el alto mínimo de acuerdo a tus necesidades
  minWidth: '100vw', // Ajusta el alto mínimo de acuerdo a tus necesidades
}) as React.FC<CSSObject>;

const Text = styled(Typography)({
  color: 'black',
});


const LoadingScreen = ({ open }: { open: boolean }) => {



  return (
    <Modal open={open}>
      <LoadingContainer>
        <Card style={{ backgroundColor: 'rgba(255, 255, 255, 1)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',width: '270px' }}>
          <CardContent>
            <Text variant="h6">En camino a su intranet...</Text>
          </CardContent>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src={imagen} style={{height:'150px'}} alt='caminar'/>
          </CardContent>
        </Card>
      </LoadingContainer>
    </Modal>
  );
};

export default LoadingScreen;
