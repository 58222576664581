import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import FirmasComponent from '../component/Firma';
import { fetchData } from '../request/firmaRequest';
import { Usuario } from '../interfaz/usuario-interfaz';
import Transition from '../msal/transition';
import Load from '../utils/loading-screen';
import { callMsGraph } from "../msal/graph";
import { loginRequest } from "../msal/authConfig";
import { useMsal } from "@azure/msal-react";
import Home from '../component/Home';

const ejecutarCodigoSegunDominio = async (correo: string) => {

  // Extraer el dominio de la dirección de correo
  const dominio = correo.split('@')[1].toLowerCase().trim();

  switch (dominio) {
    case 'ugm.cl':
      window.location.href = 'https://ugmchile.sharepoint.com/';
      break;

    case 'estudiante.ugm.cl':
      window.location.href = 'https://ugmchile.sharepoint.com/sites/Intraestudiantes';
      break;

    case 'academico.ugm.cl':
      window.location.href = 'https://ugmchile.sharepoint.com/sites/IntranetAcadmicos';
      break;

    case 'ayudante.ugm.cl':
      window.location.href = 'https://ugmchile.sharepoint.com/sites/IntranetAcadmicos';
      break;

    default:
      window.location.href = 'https://ugmchile.sharepoint.com/_layouts/15/SignOut.aspx?ru=https%3A%2F%2Fugmchile.sharepoint.com%2F&sessionId=b67b7a85-ff0b-400c-abee-1c5955f3a5e0';
      break;
  }

}
const PrivateRoute: React.FC = () => {

  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(true)

  const [state, setState] = useState<Usuario>({
    nombre: '',
    apellido: '',
    publico: '',
    anexo: '',
    area: '',
    cargo: '',
    direccion: '',
    mail: '',
  });

  const onAwaitFetch = async () => {
    try {
      await obtenerCuenta();
      const resp = await fetchData();
      console.log('resp', resp);
      setState(resp);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const obtenerCuenta = async () => {
    console.log('obtenerCuenta...')
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      console.log('response', response)

      await callMsGraph(response.accessToken);
      await ejecutarCodigoSegunDominio(response.account.username);
    } catch (error) {
      console.error('Error obtaining account:', error);
    }
  };

  useEffect(() => {

    onAwaitFetch()

  }, [])

  return loading ? (
    <Load open />
  ) : (
    <>
      <Routes>
        {/*
        <Route path="/" element={<Home callback={() => ejecutarCodigoSegunDominio(accounts[0].username)} />} />
  */}
        <Route path="/firma" element={<FirmasComponent {...state} />} />
      </Routes>
    </>
  )
};

export default PrivateRoute;
