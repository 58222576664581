import { Routes, Route } from 'react-router-dom';
import Login from '../component/Login';


const PrivateRoute: React.FC = () => {

  return (
    <>
      <Routes>
        <Route path="*" element={<Login/>}/>
      </Routes>
    </>
  )
};

export default PrivateRoute;
