import React from 'react';
import { AuthProvider } from './msal/authProvider';
import { MsalProvider, UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';
import { BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './routes/private-route';
import PublicRoute from './routes/public-route';
import { msalInstance } from './msal/authConfig';

const App: React.FC = () => {

  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>

        <AuthenticatedTemplate>
          <PrivateRoute />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <PublicRoute />
        </UnauthenticatedTemplate>

      </AuthProvider>
    </MsalProvider>
  );

};

export default App;


/**
       <Router>
        <AuthProvider>
          <AuthenticatedTemplate>
            <PrivateRoute />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <PublicRoute />
          </UnauthenticatedTemplate>
        </AuthProvider>
      </Router>
 */