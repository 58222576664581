import React from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon
} from 'mdb-react-ui-kit';

import { CSSProperties } from 'react';

import MicrosoftLoginButton from '../msal/ms-button';
const imagen = require('../assets/Logos/favicon_io (color)/android-chrome-192x192.png');

function App(): React.JSX.Element {

  const smallerTextStyle: CSSProperties = {
    fontSize: 'smaller',
    color: 'white',
    textAlign: 'center',
    display: 'block'
  };

  return (
    <MDBContainer fluid>
      <MDBRow className='d-flex justify-content-center align-items-center h-100'>
        <MDBCol col='12'>
          <MDBCard className='text-white my-5 mx-auto'
            style={{
              borderRadius: '1rem',
              maxWidth: '400px',
              backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }}
          >
            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

              <img src={imagen} alt='Logo' />

              <h2 className="fw-bold mb-2 text-uppercase">ugm</h2>
              <p className="text-white-50 mb-5">Te damos la bienvenida a Mi UGM</p>

              <MicrosoftLoginButton />

              <p className="small mb-3 pb-lg-2">
                <a className="text-white-50" href="#!">
                  Para recuperar tu cuenta, primero debes iniciar sesión en Microsoft y registrar tus datos.
                </a>
              </p>

              <p className="small mb-3 pb-lg-2">
                <a className="text-warning" href="https://youtu.be/KS0ySR3aVLE" target="_blank">¿Cómo registro mis datos?</a>
              </p>

              <p className="small mb-3 pb-lg-2">
                <a className="text-warning" href="https://youtu.be/XTUItd3tMgQ" target="_blank">
                  ¿Cómo recupero mi contraseña?
                  <p>    
                    <span style={smallerTextStyle}>(Solo Estudiantes y Académicos)</span> 
                    </p>  
                </a>
              </p>
              <MDBBtn outline className='mb-4 mx-5 w-100' color='white' size='lg' href="https://passwordreset.microsoftonline.com">
                Recuperar Contraseña
              </MDBBtn>

              <p className="small mb-3 pb-lg-2"><a className="text-white-50" href="#!">Síguenos en nuestras redes sociales</a></p>

              <div className='d-flex flex-row mt-3 mb-5'>
                <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }} href="https://web.facebook.com/ugmistral/?locale=es_LA&_rdc=1&_rdr">
                  <MDBIcon fab icon='facebook-f' size="lg" />
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }} href="https://twitter.com/ugmistral/status/1140714494794063877">
                  <MDBIcon fab icon='twitter' size="lg" />
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }} href="https://www.youtube.com/c/ugmistral/videos">
                  <MDBIcon fab icon='youtube' size="lg" />
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }} href="https://www.linkedin.com/school/universidad-gabriela-mistral/mycompany/">
                  <MDBIcon fab icon='linkedin' size="lg" />
                </MDBBtn>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default App;
