import ReactDOM from 'react-dom/client'
import App from './App'
import './App.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './msal/authConfig';
import { BrowserRouter as Router } from 'react-router-dom';

const  background  =  require('./assets/fondo.jpg');

const backgroundStyle = {
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  minHeight: '100vh', // Ajusta el alto mínimo de acuerdo a tus necesidades
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <MsalProvider instance={msalInstance}>
    <Router>
      <div className="App" style={backgroundStyle}>
        <App />
      </div>
    </Router>
  </MsalProvider>
)
