import React from 'react';
import {
    MDBBtn
  } from 'mdb-react-ui-kit';

import { useAuth } from './authProvider';
import { useIsAuthenticated } from '@azure/msal-react';

const  icono  =  require('../assets/ms-button.png');

const MicrosoftLoginButton: React.FC = () => {

    const isAuthenticated = useIsAuthenticated(); // Almacenar el valor de autenticación

    const { login, logout } = useAuth();

    const handleClick = () => {
        isAuthenticated ? logout() : login(); // Utilizar la variable isAuthenticated en la condición
    };


    return (
        <MDBBtn
            onClick={handleClick}
            style={{
                backgroundColor: 'black',
                color: 'white',
                fontWeight: 'bold',
                padding: '10px 20px',
                borderRadius: '4px',
                boxShadow: 'none',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '400px',
                margin: '0 auto'
            }}
        >
            <img
                src={icono}
                alt="Microsoft Logo"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
            />
            {isAuthenticated ? 'Cerrar sesión de cuenta Microsoft' : 'Iniciar sesión con cuenta Microsoft'}
        </MDBBtn>
    );
};

export default MicrosoftLoginButton;

