
import { Usuario } from '../interfaz/usuario-interfaz';


export const FirmaHtml = (valores:Usuario) => {

    const imagen  =  require('../assets/Logos/favicon_io (negro)/android-chrome-192x192.png');

    const {nombre, apellido, publico, area, cargo, direccion, mail} = valores

    return (`<table cellpadding="0" cellspacing="0" class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
        <tbody>
            <tr>
                <td>
                    <table cellpadding="0" cellspacing="0" class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                        <tbody>
                            <tr>
                                <td width="150" style="vertical-align: middle;"><span
                                    class="template3__ImageContainer-sc-vj949k-0 jeScQV"
                                    style="margin-right: 20px; display: block;"><img
                                        src="${imagen}"
                                        role="presentation" width="130" class="image__StyledImage-sc-hupvqm-0 gYgOut"
                                        style="max-width: 130px;"></span></td>
                                <td style="vertical-align: middle;">
                                    <h2 color="#000000" class="name__NameContainer-sc-1m457h3-0 jxbGUj"
                                        style="margin: 0px; font-size: 18px; color: rgb(0, 0, 0); font-weight: 600;">
                                        <span>${nombre}</span><span>&nbsp;</span><span>${apellido}</span></h2>
                                    <p color="#000000" font-size="medium" class="job-title__Container-sc-1hmtp73-0 ifJNJc"
                                        style="margin: 0px; color: rgb(0, 0, 0); font-size: 14px; line-height: 22px;">
                                        <span>${cargo}</span></p>
                                    <p color="#000000" font-size="medium"
                                        class="company-details__CompanyContainer-sc-j5pyy8-0 VnOLK"
                                        style="margin: 0px; font-weight: 500; color: rgb(0, 0, 0); font-size: 14px; line-height: 22px;">
                                        <span>${area}</span></p>
                                </td>
                                <td width="30">
                                    <div style="width: 30px;"></div>
                                </td>
                                <td color="#f2547d" direction="vertical" width="1" height="auto"
                                    class="color-divider__Divider-sc-1h38qjv-0 llIisW"
                                    style="width: 1px; border-bottom: none; border-left: 1px solid rgb(242, 84, 125);"></td>
                                <td width="30">
                                    <div style="width: 30px;"></div>
                                </td>
                                <td style="vertical-align: middle;">
                                    <table cellpadding="0" cellspacing="0" class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                                        <tbody>
                                            <tr height="25" style="vertical-align: middle;">
                                                <td width="30" style="vertical-align: middle;">
                                                    <table cellpadding="0" cellspacing="0"
                                                        class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                                                        <tbody>
                                                            <tr>
                                                                <td style="vertical-align: bottom;"><span color="#f2547d"
                                                                    width="11"
                                                                    class="contact-info__IconWrapper-sc-mmkjr6-1 bglVXe"
                                                                    style="display: inline-block; background-color: rgb(242, 84, 125);"><img
                                                                        src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/phone-icon-2x.png"
                                                                        color="#f2547d" alt="mobilePhone" width="13"
                                                                        class="contact-info__ContactLabelIcon-sc-mmkjr6-0 cnkwri"
                                                                        style="display: block; background-color: rgb(242, 84, 125);"></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style="padding: 0px; color: rgb(0, 0, 0);"><a href="tel:56 224144545"
                                                    color="#000000"
                                                    class="contact-info__ExternalLink-sc-mmkjr6-2 ibLXSU"
                                                    style="text-decoration: none; color: rgb(0, 0, 0); font-size: 12px;"><span>${publico}</span></a></td>
                                            </tr>
                                            <tr height="25" style="vertical-align: middle;">
                                                <td width="30" style="vertical-align: middle;">
                                                    <table cellpadding="0" cellspacing="0"
                                                        class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                                                        <tbody>
                                                            <tr>
                                                                <td style="vertical-align: bottom;"><span color="#f2547d"
                                                                    width="11"
                                                                    class="contact-info__IconWrapper-sc-mmkjr6-1 bglVXe"
                                                                    style="display: inline-block; background-color: rgb(242, 84, 125);"><img
                                                                        src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/email-icon-2x.png"
                                                                        color="#f2547d" alt="emailAddress" width="13"
                                                                        class="contact-info__ContactLabelIcon-sc-mmkjr6-0 cnkwri"
                                                                        style="display: block; background-color: rgb(242, 84, 125);"></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style="padding: 0px;"><a href="mailto:${mail}"
                                                    color="#000000"
                                                    class="contact-info__ExternalLink-sc-mmkjr6-2 ibLXSU"
                                                    style="text-decoration: none; color: rgb(0, 0, 0); font-size: 12px;"><span>${mail}</span></a>
                                                </td>
                                            </tr>
                                            <tr height="25" style="vertical-align: middle;">
                                                <td width="30" style="vertical-align: middle;">
                                                    <table cellpadding="0" cellspacing="0"
                                                        class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                                                        <tbody>
                                                            <tr>
                                                                <td style="vertical-align: bottom;"><span color="#f2547d"
                                                                    width="11"
                                                                    class="contact-info__IconWrapper-sc-mmkjr6-1 bglVXe"
                                                                    style="display: inline-block; background-color: rgb(242, 84, 125);"><img
                                                                        src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/link-icon-2x.png"
                                                                        color="#f2547d" alt="website" width="13"
                                                                        class="contact-info__ContactLabelIcon-sc-mmkjr6-0 cnkwri"
                                                                        style="display: block; background-color: rgb(242, 84, 125);"></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style="padding: 0px;"><a href="//ugm.cl" color="#000000"
                                                    class="contact-info__ExternalLink-sc-mmkjr6-2 ibLXSU"
                                                    style="text-decoration: none; color: rgb(0, 0, 0); font-size: 12px;"><span>ugm.cl</span></a>
                                                </td>
                                            </tr>
                                            <tr height="25" style="vertical-align: middle;">
                                                <td width="30" style="vertical-align: middle;">
                                                    <table cellpadding="0" cellspacing="0"
                                                        class="table__StyledTable-sc-1avdl6r-0 kAbRZI"
                                                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                                                        <tbody>
                                                            <tr>
                                                                <td style="vertical-align: bottom;"><span color="#f2547d"
                                                                    width="11"
                                                                    class="contact-info__IconWrapper-sc-mmkjr6-1 bglVXe"
                                                                    style="display: inline-block; background-color: rgb(242, 84, 125);"><img
                                                                        src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/address-icon-2x.png"
                                                                        color="#f2547d" alt="address" width="13"
                                                                        class="contact-info__ContactLabelIcon-sc-mmkjr6-0 cnkwri"
                                                                        style="display: block; background-color: rgb(242, 84, 125);"></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style="padding: 0px;"><span color="#000000"
                                                    class="contact-info__Address-sc-mmkjr6-3 jxDmGK"
                                                    style="font-size: 12px; color: rgb(0, 0, 0);"><span>${direccion}</span></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </tbody>
    </table>
    `
    )
}