import React, { createContext, useContext, useEffect, useState } from 'react';
import { PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import { msalInstance } from './authConfig';


console.log('msalInstance',msalInstance)

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}


const authContext = (children:React.ReactNode,isAuthenticated:boolean,handleLogin:() => void  ,handleLogout:() => void) => {

  return (
    <AuthContext.Provider value={{ isAuthenticated, login: handleLogin, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authInstance, setAuthInstance] = useState<PublicClientApplication | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    setAuthInstance(msalInstance);
    const accounts = msalInstance.getAllAccounts();
    setIsAuthenticated(accounts.length > 0);
  }, []);


  const handleLogin = async () => {
    if (authInstance) {
      try {
        await authInstance.loginRedirect(generateRedirectRequest());
      } catch (error) {
        console.error('Error al redirigir al inicio de sesión:', error);
      }
    }
  };

  const handleLogout = () => {
    if (authInstance) {
      authInstance.logout();
      setIsAuthenticated(false);
      navigate('/');
    }
  };


  const generateRedirectRequest = (): RedirectRequest => {
    const currentUrl = new URL(window.location.href);
    return {
      redirectUri: currentUrl.origin,
      scopes: [], // Agrega los scopes necesarios aquí
    };
  };

  return (
    authContext(children,isAuthenticated,handleLogin,handleLogout)
  );
};

export const useAuth = (): AuthContextProps => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth debe ser utilizado dentro de un AuthProvider');
  }
  return authContext;
};
