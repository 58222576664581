// Importa Axios y las definiciones de tipos
import axios, { AxiosResponse } from 'axios';
import { Usuario } from '../interfaz/usuario-interfaz';

// URL de ejemplo de JSONPlaceholder
const apiUrl = 'https://ws.ugm.cl/api/v1_intranet/firmas';

// Función asíncrona para hacer una solicitud utilizando Axios
export const fetchData = async () => {
  try {
     // Obtén el token de tu localStorage
     const accessToken = localStorage.getItem('accessToken');

   //  if (!accessToken) window.location.href = '/'
     // Configura los encabezados con el token de autorización Bearer
     const headers = {
       Authorization: `${accessToken}`,
     };
     // Realiza la solicitud GET con los encabezados configurados
     const response: AxiosResponse = await axios.get<Usuario>(apiUrl, { headers });

    // Accede a los datos de la respuesta
    const data = response.data[0] || []

    // Hacer algo con los datos
    return data 
    
  } catch (error) {
    // Maneja los errores de la solicitud
    console.error('Error al obtener datos:', error);
    return []
  }
};


//localStorage.setItem('accessToken',bearer)