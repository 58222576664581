import React, { useRef } from 'react';
import { FirmaHtml } from '../html/firmaHtml';
import { Card, Grid, Button, CardActions } from '@mui/material';
import domtoimage from 'dom-to-image';

import { Usuario } from '../interfaz/usuario-interfaz';



const FirmasComponent: React.FC<Usuario> = (valores) => {

    const firmaHtmlContent = FirmaHtml(valores);
    const cardRef = useRef(null);

    // Función para descargar la firma HTML como imagen
    const handleDownload = () => {
        if (!cardRef.current) {
            return;
        }

        domtoimage.toPng(cardRef.current)
            .then((dataUrl) => {
                const a = document.createElement('a');
                a.href = dataUrl;
                a.download = 'firma.png';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => {
                console.error('Error al convertir a imagen:', error);
            }).finally(() => {

            })
    };

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
            <Grid item>
                <Card ref={cardRef} style={{ width: 750, padding: 20, textAlign: 'left' }}>
                    <div id="prueba" dangerouslySetInnerHTML={{ __html: firmaHtmlContent }} />
                </Card>
                <CardActions>
                    <Button onClick={handleDownload} variant="contained" color="primary">
                        Descargar Firma
                    </Button>
                </CardActions>
            </Grid>
        </Grid>
    );
};

export default FirmasComponent;
